<template>
  <div class="view container broker" v-if="payload">
    <component
      :is="stepComp"
      v-model="data"
      :payload="payload"
      @navigate="$ev => (stepComp = $ev)"
      @refetchToken="refetchToken"
      @reset="reset"
    />
  </div>
</template>

<script>
import Steps from '@/components/Broker/Steps'
import Storage from '@/mixins/Storage'
import { Clone } from '@/utils'
import { jwtDecode } from 'jwt-decode'
import fetch from '@/api/fetch'

const template = {
  name: null,
  projectType: null,
  flatfinder: {
    selected: false,
    angles: 1,
    setup: null,
    images: [],
    comment: '',
  },
  domain: {
    selected: false,
    hasDomain: false,
    url: '',
    contact: '',
  },
  visualization: {
    selected: false,
  },
  billing: null,
}

export default {
  mixins: [
    Storage({ target: 'stepComp', key: 'broker-step' }),
    Storage({ target: 'data', key: 'broker-data', deep: true }),
  ],

  props: {
    step: {
      type: String,
      enum: ['order', 'products', 'summary', 'admin'],
      default: 'order',
    },
  },
  data() {
    return {
      data: Clone(template),
      payload: null,
      token: null,
      isAdmin: null,
    }
  },
  provide() {
    return {
      $payload: () => this.payload,
      $token: () => this.token,
      $isAdmin: () => this.isAdmin,
    }
  },
  computed: {
    stepComp: {
      get() {
        return this.step
      },
      set(step) {
        if (this.$route.params.step === step) return
        return this.$router.replace({
          name: this.$route.name,
          params: { ...this.$route.params, step },
          query: { ...this.$route.query },
        })
      },
    },
  },

  methods: {
    reset() {
      this.data = {
        ...Clone(template),
        name: Clone(this.payload.name),
        billing: Clone(this.payload.billing),
      }
    },
    refetchToken(callback) {
      let token = this.token ? this.token : this.$route.query.token

      const { tenant, integrationId } = this.payload ? this.payload : jwtDecode(token)

      const url = `https://${tenant}/api/integration/${integrationId}/callbacks/updateToken?token=${token}`

      fetch(url)
        .then(res => res.json())
        .then(data => {
          this.token = data.token
          this.payload = jwtDecode(data.token)
          this.isAdmin = this.payload.broker.email.split('@').pop() === 'kvass.no'

          if (!this.data.name) this.data.name = Clone(this.payload.name)
          if (!this.data.billing) this.data.billing = Clone(this.payload.billing)

          // send to order if on admin page and not has order
          if (!this.payload.hasUpcoming && !this.payload.hasProject && this.stepComp === 'admin') {
            this.stepComp = 'order'
          }

          if (callback) callback(true)
        })
        .catch(err => {
          if (callback) return callback(false)
          throw err
        })
    },
  },

  created() {
    this.refetchToken()
  },

  components: {
    ...Steps,
  },
}
</script>

<style lang="scss">
.broker {
  .alert {
    &__content {
      border-radius: inherit;
      &::before {
        border-radius: inherit;
      }
    }
    border-radius: var(--vue-elder-border-radius, 3px);
    font-size: 1em;
  }
}
</style>
