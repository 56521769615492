<template>
  <div>
    <Button label="Se bestilling" @click="() => (showOrder = true)" v-if="!hasMultipleOrders" />
    <Dropdown v-if="hasMultipleOrders">
      <template>
        <Button label="Se bestillinger"></Button>
      </template>
      <template #dropdown>
        <Button v-for="order in orders" :label="order.label" @click="order.action" />
      </template>
    </Dropdown>

    <Modal :show="showOrder" :clickaway="true" @close="() => (showOrder = false)">
      <template #default="{ close }">
        <Summary :preview="true" :payload="payload" :value="valueComp">
          <template #actions>
            <Button @click="close" label="Lukk" />
          </template>
        </Summary>
      </template>
    </Modal>
  </div>
</template>

<script>
import { DropdownComponent as Dropdown } from 'vue-elder-dropdown'
import { ButtonComponent as Button } from 'vue-elder-button'
import { ModalComponent as Modal } from 'vue-elder-modal'
import Summary from '@/components/Broker/subscription/summary.vue'

export default {
  props: {
    payload: Object,
    value: Object,
  },

  data() {
    return {
      showOrder: false,
      orderType: '',
    }
  },

  computed: {
    hasMultipleOrders() {
      return this.payload.hasProject && this.payload.hasUpcoming
    },

    orders() {
      return [
        {
          label: 'Kommer for salg side',
          action: () => {
            this.showOrder = true
            this.orderType = 'upcoming'
          },
        },
        {
          label: 'Prosjektside',
          action: () => {
            this.showOrder = true
            this.orderType = 'project'
          },
        },
      ]
    },

    valueComp() {
      const projectType = this.orderType ? this.orderType : this.payload.hasProject ? 'project' : 'upcoming'

      return {
        billing: projectType === 'project' ? this.payload.project.billing : this.payload.upcoming.billing,
        flatfinder: this.payload.flatfinder || this.value.flatfinder,
        name: projectType === 'project' ? this.payload.project.name : this.payload.upcoming.name,
        domain: this.payload.project.domain || this.payload.upcoming.domain || this.value.domain,
        visualization:
          this.payload.project.visualization || this.payload.upcoming.visualization || this.value.visualization,
        projectType,
      }
    },
  },

  components: {
    Dropdown,
    Button,
    Modal,
    Summary,
  },
}
</script>

<style lang="scss"></style>
