<template>
  <div class="product-visualization" v-bind="$attrs">
    <Card
      :thumbnail="item.image"
      :title="item.title"
      variant="horizontal"
      class="mt-2 core-broker-step-products__card"
      :class="{ 'core-broker-step-products__card--selected': item.isSelected }"
    >
      <template #default>
        <div v-html="item.description"></div>
        <Alert iconPlacement="left" class="mt-2">
          <template>
            <div v-html="item.alert"></div>
          </template>
        </Alert>
      </template>
      <template #actions>
        <div class="bold" v-html="item.price"></div>

        <Button
          @click="
            () => {
              if (item.disabled) return
              submit()
            }
          "
          label="Be om et uforpliktende tilbud"
          :class="{ 'elder-button--disabled': item.disabled }"
          v-bind="getButtonProps(item.isSelected)"
          v-tooltip="getTooltipProps(item)"
        />
      </template>
    </Card>
  </div>
</template>

<script>
import { ButtonComponent as Button } from 'vue-elder-button'
import { AlertComponent as Alert } from 'vue-elder-alert'
import Card from '@/components/Card-v2.vue'
import { toCurrency } from '@/utils'

export default {
  inject: ['$payload'],

  props: {
    value: Object,
    getTooltipProps: Function,
    getButtonProps: Function,
  },

  methods: {
    submit() {
      this.value.visualization.selected = !this.value.visualization.selected
    },
  },

  computed: {
    payload() {
      return this.$payload()
    },
    item() {
      const {
        project: { priceBroker },
      } = this.payload

      const getPrice = () => {
        return priceBroker?.visualization?.value
          ? `${toCurrency(priceBroker.visualization.value)}`
          : 'Prises etter avtale'
      }

      const title = 'Visualiseringer av prosjektet'
      return {
        value: true,
        image: 'https://assets.kvass.no/6799f098e6224bf3d13fdf37',
        title,
        description: `
        Vi leverer fotorealistiske illustrasjoner av både interiør og eksteriør til ditt prosjekt. 
        En god visualisering fremhever dine salgspunkter og fører til økt interesse. 
        Les mer om 3D visualisering fra Kvass <a target="_blank" href="https://www.kvass.no/produkter/3d-visualiseringer">her</a>`,
        disabled: !this.value.projectType,
        price: getPrice(),
        alert: `Dersom du ønsker et tilbud på visualisering fra Kvass, vil vi kontakte deg på e-post eller telefon.`,
        isSelected: this.value.visualization.selected,
        disabledText: '',
      }
    },
  },

  components: {
    Button,
    Card,
    Alert,
  },
}
</script>

<style lang="scss">
.product-visualization {
}
</style>
